import {
  CustomViewComponent,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';

CustomViewPages.register({
  id: 'marketplace_search_component',
  function: function Entity() {
    return (
      <CustomViewComponent type="card_content">
        <CustomViewComponent type="hbox">
          <CustomViewComponent type="container" size={3}>
            <CustomViewComponent
              label="Description"
              value="{{description}}"
              type="string"
              hideWhenExists="_expanded"
              colSpan="4"
            />
            <CustomViewComponent
              label="Description"
              value="{{description}}"
              type="string"
              showWhenExists="_expanded"
              maxLines="100"
              colSpan="4"
            />
            <CustomViewComponent
              label="Name"
              value="{{name}}"
              type="string"
              showWhenExists="_expanded"
              colSpan="2"
            />
            <CustomViewComponent
              label="Fully Qualified Name"
              value="{{fullyQualifiedName}}"
              type="string"
              showWhenExists="_expanded"
              colSpan="2"
            />
            <CustomViewComponent
              showWhen={{
                value: '{{tags.length}}',
                notEquals: 0,
              }}
              label="Tags"
              type="tags"
              path="tags"
            />
          </CustomViewComponent>
          <CustomViewComponent type="group" borderLeft>
            <CustomViewComponent
              type="date"
              value="{{_computedInfo.publishedAt}}"
              showWhenExists="_computedInfo.publishedAt"
              label="Published Date"
              colSpan="4"
            />
            <CustomViewComponent
              label="Version"
              value="{{version}}"
              type="string"
              showWhenExists="_expanded"
              colSpan="4"
            />
          </CustomViewComponent>
        </CustomViewComponent>
      </CustomViewComponent>
    );
  },
});
